@tailwind base;

@tailwind components;

@font-face {
  font-family: "HankenRegular";
  src: url("fonts/HKGrotesk-Regular.eot");
  src: url("fonts/HKGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/HKGrotesk-Regular.woff2") format("woff2"),
    url("fonts/HKGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HankenBold";
  src: url("fonts/HKGrotesk-Bold.eot");
  src: url("fonts/HKGrotesk-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/HKGrotesk-Bold.woff2") format("woff2"),
    url("fonts/HKGrotesk-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@tailwind utilities;
