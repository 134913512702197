html,
body {
  width: 100%;
  height: 100%;
}

body {
  background: linear-gradient(114.44deg, #f0f0f0 0%, #ffffff 100%);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "HankenRegular", sans-serif;

  background-repeat: no-repeat;
  background-attachment: fixed;
}

* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

.theform {
  height: 100vh;
  padding-bottom: 20px;
}
.hankenBold {
  font-family: "HankenBold";
  font-weight: bold;
}

.bg-line:before {
  content: "";
  width: 98%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 6px;
  border-top: 2px solid #bbc1ce;
  opacity: 0.3;
  z-index: -1;
}

.radio:first-child {
  align-items: flex-start;
}

.radio:last-child {
  align-items: flex-end;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio label {
  padding: 32px;
  margin: -32px;
}

input:checked ~ .checkmark:after {
  opacity: 1;
  display: block;
  transition: opacity 150ms ease-in-out;
}

.checkmark {
  position: relative;
  top: -8px;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 9999px;
  background-color: #fff;
  border: 2px solid #bbc1ce;
  pointer-events: none;
}

.radio .checkmark:after {
  content: "";
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  height: 28px;
  width: 28px;
  border-radius: 9999px;
  background-color: #d4ba78;
  border: 2px solid #66738f;
}

.navigation-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

header.header {
  background-color: #091026;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px 3px rgba(17, 17, 17, 0.08);
}
  header.header .marker {
    position: relative;
    width: 30px;
    height: 14px;
    margin: 0 1px;
    background-color: #3c404e;
    transition: background-color 300ms ease;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  header.header .marker .active {
    position: absolute;
    top: calc(100% + 4px);
    width: 66%;
    height: 0;
    border: 1px solid #efdfa8;
    border-radius: 1px;
  }


.marker.filled {
  background: linear-gradient(
    110deg,
    #d8ca9a 0%,
    #efdfa8 49.13%,
    #de9c5f 98.26%
  );
}
.divider {
  width: 45px;
  height: 3px;
  background-color: #d4ba78;
}

.logo {
  position: absolute;
  left: 38px;
  top: 17px;
  width: 84px;
  opacity: 0;
}

.startCard {
  background-color: #091026;
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 12px;
  margin-bottom: -16px;
}
.startCard .card {
    margin-right: 8px;
    margin-left: 8px;
    position: relative;
    padding-top: 48px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    max-width: 1024px;
    background: linear-gradient(114.44deg, #f0f0f0 0%, #ffffff 100%);
    border-radius: 10px;
    margin-top: calc(75px + 1.5rem);
}
.startCard h2 {
      font-weight: bold;
      font-size: 48px;
      padding-bottom: 6px;
    }

.startCard h3 {
      font-weight: bold;
      font-size: 36px;
      padding-bottom: 6px;
    }

.startCard img.mainlogo {
      position: absolute;
      top: -75px;
      left: 50%;
      transform: translate(-50%);
      width: 150px;
    }

.startCard button {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
      background-color: #091026;
      max-width: 20rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      height: 4rem; 
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 200ms; 
    }

    .startCard button:disabled {
      opacity: 50;
      cursor: default;
    }

    .startCard ul.list {
      list-style-type: none;
      padding-left: 1em;
    }
      ul.list li:before {
        content: "◦";
        position: absolute;
        margin-left: -1em;
      }
      ul.list li {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; 
      }
.score {
  width: 100%;
}
.resultSection {
  position: relative;
}
  .resultSection h2 {
    margin-top: 1.5rem; 
    font-size: 1.5rem;
    line-height: 2rem; 
    text-align: center;
  }
  .resultSection h3 {
    margin-top: 0.5rem; 
    font-size: 1.25rem;
    line-height: 1.75rem; 
    text-align: left;
  }
  p {
    padding-top: 1rem;
    padding-bottom: 1rem; 
  }


.sectionStar:before {
  position: absolute;
  top: 8px;
  left: 0;
  content: "";
  border: 2px solid #efdfa8;
  width: 100%;
  height: 2px;
  z-index: -1;
  opacity: 0.2;
}

@media (min-width: 768px) {
  .navigation-button {
    margin-left: 8rem;
    margin-right: 8rem; 
    max-width: 20rem; 
  }
  .startCard {
    padding-bottom: 5rem;
  }
  .startCard .card {
      max-width: 36rem; 
    }
  }


@media (min-width: 1024px) { 
  .score {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 50%;
    height: 2.5rem; 
  }

  .radio label {
    padding: 60px 20px;
    margin: -60px -20px;
  }

  header.header {
    height: 116px;
  }
  header.header .marker {
    width: 6rem; 
    height: 35px;
  }
  .marker .active {
      position: absolute;
      top: calc(100% + 8px);
      width: 66%;
      height: 0;
      border: 3px solid #efdfa8;
      border-radius: 3px;
  }

    header.header .marker:hover {
      background-color: #efdfa8;
      transition: background-color 300ms ease;
    }

  .divider {
    width: 144px;
  }

  .logo {
    opacity: 1;
  }
}
.navigation {
  top: 100%;
  left: 0;
  width: 100%;
}

.star {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  margin: 2rem auto 0;
}

input {
  width: 100%;
  height: 64px;
  border-radius: 10px;
  margin-top: 5px !important;
  padding: 0 20px !important;
}

.social-media-icon {
  fill: #091026;
}

.social-media-icon:hover {
  fill: #bdad85;
}
